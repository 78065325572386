function PageIntro({ title, headline, headline2, text }) {
  return (
    <section
      className="w-full text-center flex flex-col items-center justify-center pt-48 pb-32 px-2 bg-white"
      style={{ height: "70vh" }}
    >
      <h3 className="text-sm max-w-xl mb-8 uppercase tracking-wider text-neutral-500 font-bold">
        {title}
      </h3>
      <h2 className="text-4xl sm:text-5xl lg:text-6xl mb-12 font-bold">
        <div>{headline}</div>
        <div className="pt-2 block">{headline2}</div>
      </h2>
      <p className="text-lg lg:text-2xl sm:w-2/3 lg:w-1/2">{text}</p>
    </section>
  )
}

export default PageIntro
