import { GatsbyImage } from "gatsby-plugin-image"

function ImageDisplay({ images, selection, constrained }) {
  let featuredImages = []

  let selectedImagesArr = selection

  for (let i = 0; i < selectedImagesArr.length; i++) {
    images.forEach(item => {
      if (item.node.name === selectedImagesArr[i]) {
        featuredImages.push(item.node.childImageSharp.gatsbyImageData)
      }
    })
  }

  return (
    <>
      {featuredImages.map((image, idx) => (
        <GatsbyImage
          key={idx}
          image={image}
          className={`${!constrained ? "" : ""} mb-4 `}
          alt="Image for Design & Development"
        />
      ))}
    </>
  )
}

export default ImageDisplay
